<template>
  <div :id="`cart-shipping-${item.listingId}`">
    <template v-for="option in item.options" :key="option.type">
      <span class="body-1 mb-24 flex">
        {{ option.title }}
      </span>

      <ShippingOptions
        :listing-id="item.listingId"
        :option="option"
        :show-collection-point-error="
          shouldDisplayError &&
          hasCollectionPointError(item.options, isCollectionPointAddressSet)
        "
        @select="handleSelectOption"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { postUpdateOption } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import type {
  CartItem,
  ShippingChoice,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import ShippingOptions from '~/scopes/checkout/components/ShippingOptions.vue'
import useHandleUnauthorizedUser from '~/scopes/checkout/composables/useHandleUnauthorizedUser'
import { hasCollectionPointError } from '~/scopes/checkout/utils/hasShippingError'

const emit = defineEmits<{
  'select-option': []
}>()

defineProps<{
  shouldDisplayError: boolean
  isCollectionPointAddressSet: boolean
  item: CartItem
}>()

const tracking = useTracking()
const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const handleSelectOption = async (
  body: {
    listingId: string
    optionType: string
    optionId: string
  },
  choice: ShippingChoice,
) => {
  try {
    await $httpFetch(postUpdateOption, {
      body,
    })

    tracking.trackClick({
      zone: 'cart',
      name: choice.shipperDisplay || 'not-available',
    })

    emit('select-option')
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating shipping option',
    )
  }
}
</script>
